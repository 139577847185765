<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableNew'
import { downloadAction, getAction } from '@/command/netTool'
import { hotelMixin } from '@/mixins/orderProcessing/hotelMixin'
import { eatDrinkMixin } from '@/mixins/orderProcessing/eatDrinkMixin'
import { farmingExperienceMixin } from '@/mixins/orderProcessing/farmingExperienceMixin'
import { scenicAreasTicketsMixin } from '@/mixins/orderProcessing/scenicAreasTickets'
import { localProductsMixin } from '@/mixins/orderProcessing/localProductsMixin'
import { Tooltip } from 'ant-design-vue'
import orderConfig from '../../orderConfig'

export default {
  mixins: [hotelMixin, eatDrinkMixin, farmingExperienceMixin, scenicAreasTicketsMixin, localProductsMixin],
  data() {
    return {
      businessIdList: [],
    }
  },
  methods: {
    getTimePickerKeys() {
      const { dateTypeQuery } = this.$route.query
      switch (dateTypeQuery) {
        case 0:
          return ['dateGe', 'dateLe']
        case 1:
          return ['dateMonthGe', 'dateMonthLe']
        case 2:
          return ['dateYearStr', 'dateYearStr']
      }
    },
    getHeader() {
      return [
        {
          type: 'rangePicker',
          name: '日期',
          keys: ['dateGe', 'dateLe'],
        },
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
          typeData: [
            {
              name: '待使用',
              value: 1,
            },
            // {
            //   name: '待发货',
            //   value: 2,
            // },
            // {
            //   name: '待签收',
            //   value: 3,
            // },
            // {
            //   name: '待评价',
            //   value: 4,
            // },
            {
              name: '已完成',
              value: 5,
            },
            {
              name: '已关闭',
              value: 6,
            },
          ],
        },
        {
          type: 'input',
          key: 'id',
          name: '订单id',
        },
      ]
    },
    // 已取货
    onPickUp(data) {
      this.getDetailDataLocalProducts(data.id).then((result) => {
        data = result

        orderConfig.puikUp(
          {
            data,
            params: {
              data,
              typeData: {
                courierData: this.courierData,
              },
              type: 'specialty',
            },
            success: ({ data, setHidden }) => {
              api.order.pickedUp
                .call(this, { url: '/product/order/receivingPickup', params: { id: data.id } })
                .then(setHidden)
            },
          },
          true
        )
      })
    },
    getColumns() {
      const { fromType, businessName } = this.$route.query
      return [
        {
          // title: fromType == 13 ? '酒店名称' : '景点名称',
          title: '订单id',
          dataIndex: 'orderId',
          customRender: (text, records) => {
            return records.id
          },
        },
        {
          title: fromType == 13 ? '出售房间' : '商品名称',
          dataIndex: 'productName',
          customRender: (text, records) => {
            return (
              <div>
                {records.itemList.map((item, i) => {
                  return (
                    <div
                      style={{
                        borderTop: i == 0 ? 'none' : '1px solid #e1e1e1',
                        padding:
                          i == 0 ? (records.itemList.length == 1 ? '0px 0 0px 0px' : '0px 0 6px 0px') : '6px 0px',
                      }}
                      class="product-order-item-copy"
                    >
                      {item.productPic ? <img src={item.productPic.split(',')[0]} /> : ''}
                      <Tooltip title={item.productName}>
                        <div>{item.productName}</div>
                      </Tooltip>
                    </div>
                  )
                })}
              </div>
            )
          },
        },
        {
          // title: fromType == 13 ? '酒店名称' : '景点名称',
          title: '商户名称',
          dataIndex: 'businessName',
          customRender: () => {
            return businessName
          },
        },
        {
          title: '购买人',
          dataIndex: 'concatName',
          customRender: (_, records) => {
            return <div>{records.concatName || records.buyName}</div>
          },
        },
        {
          title: '手机号',
          dataIndex: 'concatPhone',
          customRender: (_, records) => {
            return <div>{records.concatPhone || records.buyPhone}</div>
          },
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
          customRender: (text, records) => {
            return (
              <div>
                {records.itemList.map((item, i) => {
                  return (
                    <div
                      style={{
                        borderTop: i == 0 ? 'none' : '1px solid #e1e1e1',
                        padding:
                          i == 0 ? (records.itemList.length == 1 ? '0px 0 0px 0px' : '0px 0 6px 0px') : '6px 0px',
                      }}
                    >
                      {item.productQuantity}
                    </div>
                  )
                })}
              </div>
            )
          },
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          customRender: (text, records) => {
            let newText = text
            if (records.refundAmount) {
              newText = newText + `(退款:${records.refundAmount})`
            }
            return newText
          },
        },
        {
          title: '核销时间',
          dataIndex: 'usedTime',
          customRender: (text, records) => {
            return text || '-'
          },
        },
        {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
        },
        {
          title: '核销金额',
          dataIndex: 'totalAmountCompleted',
        },
        {
          title: '退款数量',
          dataIndex: 'refundCount',
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
        },
        {
          dataIndex: 'salesJsAmount',
          title: '结算金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <span>{(records.salesJsAmount || 0).toFixed(2)}</span>
              </div>
            )
          },
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (data, record) => {
            const state =
              data === '102'
                ? '待确认'
                : ['待支付', '待使用', record.pickUpId ? '待取货' : '待发货', '待签收', '待评价', '已完成', '已关闭'][
                    data
                  ]
            return <div>{state}</div>
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          align: 'center',
          customRender: (text, records) => {
            return (
              <a
                onClick={() => {
                  const fromType = records.fromType
                  switch (fromType) {
                    case '1':
                      if (records.pickUpId) {
                        this.onPickUp(records)
                      } else {
                        this.onSelectRowLocalProducts(records, true)
                      }
                      break
                    case '2':
                      this.onRowSelectEatDrink(records.id)
                      break
                    case '4':
                      this.onRowSelectFarmingExperience(records.id)
                      break
                    case '12':
                      this.onRowSelectScenicAreasTickets(records, null, true)
                      break
                    case '13':
                    case '14':
                      this.onRowSelectHotel(records, true)
                      break
                  }
                }}
              >
                详情
              </a>
            )
          },
        },
      ]
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const { dateLe, dateGe, dateMonthLe, dateMonthGe, dateYearStr, dateTypeQuery, fromType, status } =
              this.$refs.table.params
            const { businessId } = this.$route.query
            let urlParam = `fromType=${fromType || ''}&dateTypeQuery=${dateTypeQuery || ''}&dateMonthLe=${
              dateMonthLe || ''
            }&dateMonthGe=${dateMonthGe || ''}&dateLe=${dateLe || ''}&dateGe=${dateGe || ''}&dateYearStr=${
              dateYearStr || ''
            }&businessId=${businessId}&status=${status || ''}`

            if (this.businessIdList.length > 0) {
              urlParam = `orderIdList=${this.businessIdList}`
            }

            downloadAction(`/api/reportForm/exportOrderTotal?${urlParam}`, '商户报表详情.xlsx')
          },
        },
      ]
    },
    selectRow(data, records) {
      this.businessIdList = records.map((d) => d.id)
    },
  },
  render() {
    const { dateLe, dateGe, dateMonthLe, dateMonthGe, dateYearStr, dateTypeQuery, fromType, businessId } =
      this.$route.query
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        url={`/reportForm/getOrderPage`}
        pageParams={{ dateTypeQuery, dateLe, dateGe, dateMonthLe, dateMonthGe, dateYearStr, fromType, businessId }}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        // resetDefaultData={{
        //   fromType,
        // }}
        filterRightButtonKey={['refresh', 'setting']}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
